import React from 'react'
import { graphql } from 'gatsby'
import HubspotForm from 'react-hubspot-form'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import Layout from '../layouts/layout'
import Hero from '../components/Hero/Hero'
import Container from '../components/container'
import SEO from '../components/seo'
import SocialIcons from '../components/SocialIcons/SocialIcons'

const Row = styled.div`
    @media (min-width: 769px) {
        display: flex;
    }
`

const SidebarWidget = styled.div`
    width: 400px;
    max-width: 100%;
    background-color: var(--color-licorice);
    color: #fff;
    align-self: flex-start;
    border-radius: 5px;
    overflow: hidden;
`

const SidebarContent = styled.div`
    padding: 2rem;
`

const SidebarTitle = styled.h3`
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 0.5em;
`

const Address = styled.address`
    font-style: normal;
    font-size: 0.9rem;
    color: var(--color-light-blue);
`

export default function Contact({ data }) {
    const mapImage = data.mapImage.childImageSharp.fluid
    return (
        <Layout>
            <SEO title="Contact" />
            <Hero image={data.heroImage} title="Contact" />
            <Container>
                <p css={{ marginTop: '1rem', marginBottom: '2rem' }}>
                    Bluespec, headquartered in Massachusetts, specializes in
                    delivering RISC-V processors and accelerators to the FPGA
                    and ASIC markets. We have representation across the US and
                    are actively involved in the RISC-V Foundation. If you would
                    like to learn more about our technology fill out the form
                    below or reach out to sales directly by phone.
                </p>
                <Row>
                    <div css={{ flex: 1 }}>
                        <HubspotForm
                            className="hubspot"
                            portalId="3621575"
                            key="3c967c78-c108-4208-a5dd-280ec0e64174"
                            formId="3c967c78-c108-4208-a5dd-280ec0e64174"
                        />
                    </div>
                    <SidebarWidget>
                        <Img fluid={mapImage} />
                        <SidebarContent>
                            <SidebarTitle>Contact Info</SidebarTitle>
                            <Address>
                                1671 Worcester Road, Suite 101
                                <br />
                                Framingham, MA 01701
                                <br />
                                <a
                                    href="mailto:sales@bluespec.com"
                                    css={{
                                        color: 'var(--color-light-blue)',
                                        textDecoration: 'none',
                                    }}
                                >
                                    sales@bluespec.com
                                </a>
                                <br />
                                <a
                                    href="mailto:pr@bluespec.com"
                                    css={{
                                        color: 'var(--color-light-blue)',
                                        textDecoration: 'none',
                                    }}
                                >
                                    pr@bluespec.com
                                </a>
                            </Address>
                            <SocialIcons />
                        </SidebarContent>
                    </SidebarWidget>
                </Row>
            </Container>
        </Layout>
    )
}

export const query = graphql`
    query {
        heroImage: file(relativePath: { eq: "contact-bg@2x.png" }) {
            ...heroImage
        }
        mapImage: file(relativePath: { eq: "map.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
